import React from 'react';
import { graphql } from 'gatsby'
import { css } from '@emotion/react';
import { setName } from '../extra/util';
import FloatingCard from '../components/floating-card';

export default function Theme({ data }) {
  const lf = new Intl.ListFormat('en-AU');

  setName('Offsite');

  return (
    <>
      <div
        className="type--50"
        css={css`
          background-color: var(--white);
          padding: var(--margin);
        `}
      >
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
            gap: var(--gap);
          `}
        >
          {data.work.nodes.length > 0 && data.work.nodes
            .filter((work) => work.themes.some((theme) => theme.id === data.theme.id))
            .map((work, i) => <FloatingCard
              key={i}
              url={`/offsite/work/${work.slug.current}`}
              color={work.collection?.color?.hex}
              image={work.featuredImage}
              title={
                <>
                  {work.people.length > 0 && `${lf.format(work.people.map((person) => person.name))}, `}<i>{work.title}</i>
                </>
              }
              oppositeFrame
            />)
          }
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query ThemeTemplateQuery($slug: String!) {
    theme: sanityTheme(slug: { current: { eq: $slug } }) {
      id
    }
    work: allSanityWork {
      nodes {
        id
        title
        slug {
          current
        }
        collection {
          color {
            hex
          }
        }
        themes {
          id
        }
        featuredImage {
          ...Image
        }
        people {
          name
        }
      }
    }
  }
`;
